import React from "react";
import PropTypes from "prop-types";
import HeroTitle from "./HeroTitle";
import HeroDescription from "./HeroDescription";
const HeroHeadText2 = ({ headerWithDesc }) => {
  if (headerWithDesc) return <HeroDescription />;
  else return <HeroTitle />;
};

HeroHeadText2.propTypes = {
  headerWithDesc: PropTypes.bool,
  versionNumber: PropTypes.string,
};

export default HeroHeadText2;
