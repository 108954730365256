import React, { useState } from "react";

import PropTypes from "prop-types";
import ModalErrorHandler from "../../modals/ModalErrorHandler";
import FacebookLoginButton from "../../common/FacebookLoginButton";
import GoogleLoginButton from "../../common/GoogleLoginButton";
import Visibility from "@mui/icons-material/Visibility";
import LinkedInLoginButton from "../../common/LinkedInLoginButton";
import TwitterLoginButton from "../../common/TwitterLoginButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Grid,
  TextField,
  FormControl,
  InputAdornment,
  IconButton,
  Divider,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { useAuth } from "../../../components/context/auth";

const HeroReusableFormV2 = ({ versionNumber, utm_campaign, ColorButton }) => {
  const { signUp, showErrors, errorsArr } = useAuth();

  const initialFormState = {
    fullname: "",
    email: "",
    password: "",
    confirmPassword: "",
    skill: "",
    coupon: utm_campaign ? utm_campaign : "",
  };
  const [
    { fullname, email, password, confirmPassword, skill, coupon },
    setState,
  ] = useState(initialFormState);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }

    const payload = {
      fullname,
      email,
      password,
      confirmPassword,
      skill,
      coupon,
    };
    await signUp(payload);
  };
  const theme = useTheme({
    breakpoints: {
      values: {
        sm: 0,
        md: 960,
        lg: 1200,
        xl: 2100,
      },
    },
  });

  return (
    <Grid className={`signInFormWrap${versionNumber}`}>
      <form id="signupForm" onSubmit={handleSubmit} method="POST">
        {showErrors && <ModalErrorHandler errorsArr={errorsArr} />}
        <Grid id="top-row" container>
          <Grid item className={`signInForms${versionNumber}`} xs={6}>
            <TextField
              size="small"
              fullWidth
              color="primary"
              label="Your name"
              variant="outlined"
              type="text"
              name="fullname"
              value={fullname}
              onChange={handleInputChange}
              minLength="1"
              maxLength="25"
              pattern="^[a-zA-Z. ]+"
            />
          </Grid>
          <Grid className={`signInForms${versionNumber}`} item xs={6}>
            <TextField
              size="small"
              fullWidth
              width="100%"
              label="Email"
              variant="outlined"
              type="email"
              name="email"
              value={email}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid id="bottom-row" container>
            <Grid item className={`signInForms${versionNumber}`} xs={6}>
              <FormControl variant="outlined" fullWidth size="small">
                <TextField
                  label="Password"
                  fullWidth
                  size="small"
                  id="outlined-adornment-password"
                  type={passwordVisible ? "text" : "password"}
                  inputProps={{
                    autoComplete: "new-password",
                  }}
                  value={password}
                  name="password"
                  onChange={handleInputChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setPasswordVisible(!passwordVisible)}
                          edge="end"
                          size="large"
                        >
                          {passwordVisible ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>

            <Grid className={`signInForms${versionNumber}`} item xs={6}>
              <TextField
                size="small"
                fullWidth
                inputProps={{
                  autoComplete: "new-password",
                }}
                label="Confirm password"
                variant="outlined"
                type={passwordVisible ? "text" : "password"}
                name="confirmPassword"
                value={confirmPassword}
                onChange={handleInputChange}
                minLength="8"
                maxLength="20"
                // required
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid
            item
            sx={{
              margin: "0 auto",
            }}
          >
            <div className={`joinNowBtnWrapper${versionNumber}`}>
              <ColorButton
                variant="contained"
                color="primary"
                className="joinNowLanding"
                type="submit"
                style={{ color: "white" }}
              >
                Join Now
              </ColorButton>
            </div>
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              sx={{
                [theme.breakpoints.down("md")]: {
                  maxWidth: "450px",
                },
              }}
            >
              <div className={`contWrap${versionNumber}`}>
                <Grid
                  container
                  direction="row"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  margin="0 auto 1rem auto"
                >
                  {/* start of continue with */}
                  <Grid
                    item
                    sx={{
                      margin: "0 auto",
                    }}
                  >
                    <Divider
                      sx={{
                        width: "100%",
                        marginRight: "60px",
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <div className={`contDiv${versionNumber}`}>
                      <span className={`continueWithLanding${versionNumber}`}>
                        Or continue with
                      </span>
                    </div>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      margin: "0 auto",
                    }}
                  >
                    <Divider
                      sx={{
                        width: "100%",
                        marginRight: "60px",
                      }}
                    />
                  </Grid>
                  {/* end of continue with */}
                </Grid>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid item>
                    <LinkedInLoginButton active={true} />
                  </Grid>
                  <Grid item>
                    <GoogleLoginButton active={true} />
                  </Grid>
                  <Grid item>
                    <FacebookLoginButton active={true} />
                  </Grid>
                  <Grid item>
                    <TwitterLoginButton active={true}/>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};
HeroReusableFormV2.propTypes = {
  versionNumber: PropTypes.string,
  ColorButton: PropTypes.object,
};
export default HeroReusableFormV2;
