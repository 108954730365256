/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react';
import useScreenSizeHero from '../../customHooks/useScreenSizeHero';
import Proptypes from 'prop-types';
import { useRouter } from 'next/router';
// import ModalErrorHandler from "../ModalErrorHandler";
import HeroHeadText2 from './HeroHeadText2';
import { Grid, Typography, Link } from '@mui/material';
import StarChart from '../StarChart';
import HeroReusableForm1 from './HeroReusableForm1';
import HeroReusableFormV2 from './HeroReusableFormV2.js';
import dynamic from 'next/dynamic';

const HeroAnimation = dynamic(() =>
  import('components/marketing/HeroAnimation.js')
);

const HeroReusableV2 = ({
  headerWithDesc,
  versionNumber,
  Animation,
  ColorButton,
}) => {
  const screenSize = useScreenSizeHero();
  const [paused, setPaused] = React.useState(true);
  // Router
  const router = useRouter();
  const { utm_campaign } = router.query;
  //Inserting classes based on conditions since the classNames arent consistent.
  const dynamicNumbers =
    versionNumber === '102'
      ? '102-M'
      : versionNumber === '002'
      ? '002-M'
      : versionNumber === '012'
      ? '012-M'
      : versionNumber;

  return (
    <div className="layout-container">
      {screenSize === 'largeScreen' ? (
        <Grid
          onMouseOver={() => setPaused(false)}
          onMouseOut={() => setPaused(true)}
          container
          alignItems="center"
          justifyContent="center"
        >
          <Grid item lg={5} md={12} sm={12}
          sx={{
            ...(versionNumber === '012' && {
              marginTop: '.12rem !important',
            }),
          }}>
            <Grid
              direction="column"
              justifyContent="center"
              alignItems="center"
              container
            >
              <HeroHeadText2
                headerWithDesc={headerWithDesc}
                versionNumber={versionNumber}
              />
              <HeroReusableFormV2
                ColorButton={ColorButton}
                utm_campaign={utm_campaign}
                versionNumber={versionNumber}
              />
              <Typography
                style={{
                  fontFamily: 'Open Sans',
                  fontWeight: '400',
                  color: 'rgba(0, 27, 17, 0.7)',
                  marginTop: '15px',
                  overflow: 'break-word',
                  wordWrap: 'break-word',
                  whiteSpace: 'normal',
                  width: '400px',
                  margin: '15px auto',
                  textAlign: 'center',
                }}
              >
                By signing up to TeammateMe, you agree to our{' '}
                <Link href="/termsandconditions">terms & conditions</Link> and{' '}
                <Link href="/privacypolicy">privacy policy.</Link>
              </Typography>
            </Grid>
          </Grid>
          <Grid item lg={7} md={12} sm={12}
          sx={{
            ...(versionNumber === '102' && {
              marginTop: '-1.8rem !important',
            }),
            ...(versionNumber === '012' && {
              marginTop: '.1rem !important',
            }),
          }}>
            <Grid container justifyContent="center" >
              {Animation === 'HeroAnimation' ? (
                <HeroAnimation
                  paused={screenSize === 'smallScreen' ? false : paused}
                />
              ) : (
                <StarChart
                  paused={screenSize === 'smallScreen' ? false : paused}
                  margin={screenSize === 'smallScreen' ? " auto auto 2rem" : "auto" }
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid
          onMouseOver={() => setPaused(false)}
          onMouseOut={() => setPaused(true)}
          container
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <HeroHeadText2
                  headerWithDesc={headerWithDesc}
                  versionNumber={
                    versionNumber === '102' || versionNumber === '012'
                      ? '002'
                      : versionNumber
                  }
                />
              </Grid>
              <Grid item >
                <HeroReusableForm1
                  AddNameInputField={true}
                  AddTwitterButton={true}
                  utm_campaign={utm_campaign}
                  ColorButton={ColorButton}
                  versionNumber={dynamicNumbers}
                />
              </Grid>
              <Grid item>
                <Typography
                  style={{
                    fontFamily: 'Open Sans',
                    fontWeight: '400',
                    color: 'rgba(0, 27, 17, 0.7)',
                    marginTop: '15px',
                    // marginLeft: '30px',
                    overflow: 'break-word',
                    wordWrap: 'break-word',
                    whiteSpace: 'normal',
                    width: '300px',
                    textAlign: 'center',
                  }}
                >
                  By signing up to TeammateMe, you agree to our{' '}
                  <Link href="/termsandconditions">terms & conditions</Link> and{' '}
                  <Link href="/privacypolicy">privacy policy.</Link>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {Animation === 'HeroAnimation' ? (
              <div className={`svgContainer${versionNumber}-M`} maxWidth="100vw">
                <HeroAnimation
                  paused={screenSize === 'smallScreen' ? false : paused}
                />
              </div>
            ) : (
              <StarChart
                paused={screenSize === 'smallScreen' ? false : paused}
                margin={screenSize === 'smallScreen' ? "3.1rem auto 3.5rem" : "auto" }
              />
            )}
          </Grid>
        </Grid>
      )}
    </div>
  );
};

HeroReusableV2.propTypes = {
  headerWithDesc: Proptypes.bool,
  versionNumber: Proptypes.string,
  Animation: Proptypes.string,
  ColorButton: Proptypes.object,
};
export default HeroReusableV2;
